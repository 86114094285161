import './Localization.css';
// Constants
import {LANGUAGE_EN, LANGUAGE_MK} from '../../service/localization/localization';

export default function Localization(props) {
  console.log('props: ' + props)
  const {setLang, lang} = props;
  
  const switchLanguage = (currentLang, newLang) => {
    // If we select the same language, don't do anything
    if (currentLang === newLang) return;
    localStorage.setItem('control-center-language', newLang);
    setLang(newLang);
  };
  
  return (<>
    <div className="domore-localization-wrapper">
      <span className={ `domore-localization-button ${ lang === LANGUAGE_EN ? 'selected' : '' }` }
            id="language-select-en"
            onClick={ () => switchLanguage(lang, LANGUAGE_EN) }>
        { LANGUAGE_EN }
      </span>
      <span className="user-select-none"> | </span>
      <span className={ `domore-localization-button ${ lang === LANGUAGE_MK ? 'selected' : '' }` }
            id="language-select-mk"
            onClick={ () => switchLanguage(lang, LANGUAGE_MK) }>
        { LANGUAGE_MK }
      </span>
    </div>
    { props.children }
  </>);
}
