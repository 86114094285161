import * as React from 'react';
import {useHistory, useParams} from "react-router-dom";
import {getFamily} from "../../service/FamilyService";
import {useGlobalSpinnerContext} from "../../context/GlobalSpinnerContext";
import moment from "moment";
import {Button, Modal} from "react-bootstrap";
import {GlobalSpinner} from "../shared/GlobalSpinner";


export default function FamilyDetails() {
  const {id} = useParams();
  const history = useHistory();
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [family, setFamily] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [isCancelled, setIsCancelled] = React.useState(false);

  React.useEffect(() => {
    if (!isLoaded) {
      getFamily(id).then(response => {
        setFamily(response.data);
        setShow(false);
        setIsLoaded(true);
      })
        .catch(error => console.log(error));
    }
  })

  return (
    <div className="container mt-5">
      {isLoaded && (
        <div>
          <h2> Family details </h2>
          <hr/>
          <div>
            {/*<h4><b>Date:</b></h4>*/}
            {/*<h5>{moment(family.startTime).format("DD-MM-YYYY")}</h5>*/}
            {/*<h4 className="mt-3"><b>From:</b></h4>*/}
            {/*<h5>{moment(family.startTime).format("HH:mm")}</h5>*/}
            {/*<h4 className="mt-3"><b>To:</b></h4>*/}
            {/*<h5>{moment(family.endTime).format("HH:mm")}</h5>*/}
            <h4 className="mt-3"><b>First Name:</b></h4>
            <h5>{family.firstName}</h5>
            <h4 className="mt-3"><b>Last Name:</b></h4>
            <h5>{family.lastName}</h5>
            <h4 className="mt-3"><b>Phone:</b></h4>
            <h5>{family.phone}</h5>
            <h4 className="mt-3"><b>Address:</b></h4>
            <h5>{family.address}</h5>
            <h4 className="mt-3"><b>Description:</b></h4>
            <h5>{family.description}</h5>
            {/*{isPatientRole() && (*/}
            {/*  <h4 className="mt-3"><b>Doctor:</b> {family.doctor.firstName + " " + family.doctor.lastName}*/}
            {/*    &nbsp;(Phone: {family.doctor.phone})</h4>*/}
            {/*)}*/}

            {/*{isDoctorRole() && (*/}
            {/*  <h4 className="mt-3"><b>Patient:</b> {family.patient.firstName + " " + family.patient.lastName}*/}
            {/*    &nbsp;(Phone: {family.patient.phone})</h4>*/}
            {/*)}*/}
          </div>

           <button className="btn btn-outline-danger mt-5 mr-3" onClick={() => history.goBack()}>Back</button>

        </div>
      )}
    </div>
  );
}
