import * as React from 'react';

// Context
import {GlobalSpinnerContext} from "../../context/GlobalSpinnerContext";

export default function GlobalSpinnerProvider(props) {
  const [spinnerCounter, setSpinnerCounter] = React.useState(0);

  const showSpinner = React.useCallback(() => {
    setSpinnerCounter(spinner => spinner + 1);
  }, []);

  const hideSpinner = React.useCallback(() => {
    setSpinnerCounter(spinner => spinner - 1);
  }, []);

  return (
    <GlobalSpinnerContext.Provider value={[spinnerCounter, showSpinner, hideSpinner]}>
      {props.content}
    </GlobalSpinnerContext.Provider>
  );
}
