import {Navbar, Nav} from "react-bootstrap";
import logo from '../do_more_logo_4.png';
import {Link} from "react-router-dom";
import Localization from "../localization/Localization";
import {useMessageSource} from "react-message-source";
import './Header.css';

export default function Header(props) {
  const headerMessageSource = useMessageSource('header');
  const zaNasMessageSource = useMessageSource('zanas');
  
  return (
    <>
      <Navbar expand="lg" variant="dark" className="domore-navbar">
        <Navbar.Brand href="/" className="domore-navbar-brand">
          <img src={ logo } className="domore-navbar-brand-logo" alt="DoMore Logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="domore-navbar-toggler"/>
        <Navbar.Collapse id="basic-navbar-nav" className="domore-navbar-collapse">
          <Nav className="me-auto">
            <Link to="/" className="nav nav-link">{ headerMessageSource.getMessage('zanas') }</Link>
            <Link to="/doniraj" className="nav nav-link">{ headerMessageSource.getMessage('boxtohelp') }</Link>
            <Link to="/top-donatori" className="nav nav-link">{ headerMessageSource.getMessage('topdonatori') }</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="domore-navbar-sub-header">
        <div className="domore-navbar-sub-header-content">
          { zaNasMessageSource.getMessage('napravipoveke.description') }
        </div>
        <div className="domore-navbar-sub-header-content">
          <Localization setLang={ props.setLang } lang={ props.lang }/>
          <a href="mailto:mkdomore@gmail.com" style={ {color: 'white'} }>mkdomore@gmail.com</a>
        </div>
      </div>
    </>
  )
}
