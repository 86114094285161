import React, {useState} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

// Style
import './App.css';

// Components
import Pocetna from "./components/Pocetna";
import Login from "./components/authentication/Login";
import {GlobalSpinner} from "./components/shared/GlobalSpinner";
import GlobalSpinnerProvider from "./components/shared/GlobalSpinnerProvider";
import PrivateRoute from "./components/shared/PrivateRoute";
import Families from "./components/family/Families";
import Footer from "./components/shared/Footer";
import FamilyDetails from "./components/family/FamilyDetails";
import AddFamily from "./components/family/AddFamily";
import Header from "./components/shared/Header";
import ZaNas from "./components/ZaNas";
import TopDonatori from "./components/TopDonatori";
import {Provider} from "react-message-source";
import getLocalizationMessages, {DEFAULT_LANGUAGE} from "./service/localization/localization";

const style = {
  minHeight: '100vh',
  height: '100%'
}

function App() {
  const [lang, setLang] = useState(DEFAULT_LANGUAGE);

  return (
    <GlobalSpinnerProvider content={
      <>
        <GlobalSpinner/>
        <Provider value={getLocalizationMessages(lang)}>
          <Router>
            <Header lang={lang} setLang={setLang}/>
            <div style={style}>
              <Route exact path="/" render={() => <ZaNas lang={lang}/>}/>
              <Route exact path="/doniraj" render={() => <Pocetna lang={lang}/>}/>
              <Route exact path="/top-donatori" component={TopDonatori}/>
              <PrivateRoute path="/families" component={Families}/>
              <PrivateRoute path="/new/family" component={AddFamily}/>
              <PrivateRoute path="/family/:id" component={FamilyDetails}/>
              <Route path="/login" component={Login}/>
            </div>
          </Router>
          <Footer/>
        </Provider>
      </>
    }/>
  );
}

export default App;
