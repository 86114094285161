import {Button, Modal} from "react-bootstrap";
import EditFamily from "../family/EditFamily";
import {GlobalSpinner} from "./GlobalSpinner";
import * as React from "react";
import {useMessageSource} from "react-message-source";

const footerStyle = {
  position: 'absolute',
  backgroundColor: '#FC1D37',
  color: 'black',
  textAlign: 'center',
  marginTop: '100px',
  width: '100%',
  height: '100px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
}
export default function Footer() {

  const [show, setShow] = React.useState(false);

  const {getMessage} = useMessageSource('footer');

  return (
    <div style={ footerStyle }>
      <div style={{ width: '400px', color: '#fff'}}>{getMessage('prava.title')}</div>
      <Button variant="link" href="https://www.facebook.com/napravipoveke.domore/" target="_blank"
              style={ {color: '#fff', backgroundColor: '#FC1D37', borderColor: '#FC1D37'} }><i
        className="fab fa-2x fa-facebook-f"></i></Button>
      <Button variant="link" href="https://www.instagram.com/napravipoveke.domore/" target="_blank"
              style={ {color: '#fff', backgroundColor: '#FC1D37', borderColor: '#FC1D37'} }><i
        className="fab fa-2x fa-instagram"></i></Button>
      <Button variant="link" href="https://www.linkedin.com/company/domoremk/" target="_blank"
              style={ {color: '#fff', backgroundColor: '#FC1D37', borderColor: '#FC1D37'} }><i
        className="fab fa-2x fa-linkedin"></i></Button>
      <div style={{ width: '400px'}}><Button style={ {color: '#fff', backgroundColor: '#FC1D32', borderColor: '#FC1D37'} } onClick={() => setShow(true)}>{getMessage('politika.title')}</Button></div>
      <Modal show={show} onHide={() => setShow(false)} >
        <Modal.Header>
          <Modal.Title>{getMessage('politika.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>{getMessage('politika1')}</li>
            <li>{getMessage('politika2')}</li>
            <li>{getMessage('politika3')}</li>
            <li>{getMessage('politika4')}</li>
            <li>{getMessage('politika5')}</li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}
