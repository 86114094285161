import * as React from "react";
import srce from './do-more-srce.png';
import {useGlobalSpinnerContext} from "../context/GlobalSpinnerContext";
import {getDonations, getDonors} from "../service/FamilyService";
import {useMessageSource} from "react-message-source";

export default function TopDonatori() {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [donors, setDonors] = React.useState([]);
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext()

  const {getMessage} = useMessageSource('topdonatori');


  React.useEffect(() => {
    if (!isLoaded) {
      showSpinner();
      getDonors()
        .then(response => {
          setDonors(response.data.donors);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoaded(true);
          hideSpinner();
        });
    }
  }, [isLoaded, showSpinner, hideSpinner])

  return (
    <div className="container mt-5 text-center" style={{fontFamily: 'Nunito'}}>
      <h2 style={{color: '#fc1d37'}}><b>{getMessage("title")}</b></h2>
      <br/>
      <h4>{getMessage("description1")} <br/>
        {getMessage("description2")} <br/>
        {getMessage("description3")} <br/>
        {getMessage("description4")} <br/>
        {getMessage("description5")}</h4>
      <br/>
      <br/>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 style={{color: '#1723CB'}}>{getMessage("topdonatori")}</h2>
            <ul className="list-group" style={{backgroundColor: '#1723CB'}}>
              {donors.map((donor, index) => <li className="list-group-item" style={{
                backgroundColor: '#1723CB',
                color: 'white',
                borderColor: 'white'
              }}>{index+1}. {donor}</li>)}
              <li className="list-group-item" style={{
                backgroundColor: '#1723CB',
                color: 'white',
                borderColor: 'white'
              }}><img src={srce} style={{ width: '3%'}} alt="Срце"/></li>
            </ul>
          </div>
          {/*<div className="col">*/}
          {/*  <h2 style={{ color: '#1723CB'}}>ТОП Индивидуалци</h2>*/}
          {/*  <li>asd</li>*/}
          {/*  <li>asd</li>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  )
}
