import axios from "axios";

// Services
import {logout} from "../service/AuthService";

const instance = axios.create({
  baseURL: 'https://domoremk.com/boxtohelp/',
  // baseURL: 'http://localhost:8080/',
  headers: {
    'Access-Control-Allow-Origin' : '*'
  }
})

instance.interceptors.response.use(response => response,
    error => {
  if (error.response.status === 401) {
    logout();
    window.location.reload();
  }

  return Promise.reject(error);
})

export default instance;
