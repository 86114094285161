import * as React from 'react';

// Context
import {useGlobalSpinnerContext} from "../../context/GlobalSpinnerContext";

const SpinnerContainer = {
  position: 'fixed',
  height: '100%',
  width: '100%',
  opacity: '1',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  top: '0',
  left: '0',
  zIndex: '100'
};

export function GlobalSpinner() {
  const [isGlobalSpinnerOn] = useGlobalSpinnerContext();

  return isGlobalSpinnerOn > 0 ? (
    <div style={SpinnerContainer}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  ) : null;
}
