import messages_en from '../../localization/messages-en.json';
import messages_mk from '../../localization/messages-mk.json';

export const DEFAULT_LANGUAGE = 'MK';
export const LANGUAGE_EN = 'EN';
export const LANGUAGE_MK = 'MK';

export default function getLocalizationMessages(lang) {

  switch (lang.toUpperCase()) {
    case 'EN':
      return messages_en;
    case 'MK':
      return messages_mk;
    default:
      return messages_mk;
  }
}
