import axios from "../custom-axios/axios";

const API_URL = "https://domoremk.com/boxtohelp/api/auth/";
// const API_URL = "http://localhost:8080/api/auth/";


export function login(email, password) {
  return axios
    .post(API_URL + "signin", {
      email,
      password
    })
    .then(response => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data))
      }

      return response.data;
    });
}

export function logout() {
  localStorage.removeItem("user");
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user"));
}
