import * as React from "react";

// Services
import moment from "moment";
import {Button, Modal} from "react-bootstrap";
import {useGlobalSpinnerContext} from "../../context/GlobalSpinnerContext";
import {GlobalSpinner} from "../shared/GlobalSpinner";
import {Link} from "react-router-dom";
import AddFamily from "./AddFamily";
import EditFamily from "./EditFamily";


export default function FamilyRow(props) {
  const [show, setShow] = React.useState(false);
  const {family, setIsLoaded} = props;

  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext();

  return (
    <tr key={family.id}>
      <th scope="row">
        {family.lastName}
      </th>
      {/*{(isDoctorRole() || isAdminRole()) && <td>{family.patient.firstName + " " + family.patient.lastName}</td>}*/}
      {/*{(!isDoctorRole() || isAdminRole()) && <td>{family.doctor.firstName + " " + family.doctor.lastName}</td>}*/}
      <td>{family.firstName}</td>
      <td>{family.phone}</td>
      <td>{family.address}</td>
      <td>
        <Link to={"/family/" + family.id}><button className="btn btn-outline-info mr-1">Детали</button></Link>
        <button className="btn btn-outline-danger" onClick={() => setShow(true)}>Едитирај</button>
      </td>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Едитирај семејство</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditFamily family={family} setShow={setShow}/>
        </Modal.Body>
        <GlobalSpinner />
      </Modal>
    </tr>
  );
}
