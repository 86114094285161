import * as React from "react";
import crna from './[RGB] BOX2HELP-crna.png';
import plava from './[RGB] BOX2HELP-plava.png';
import crvena from './[RGB] BOX2HELP-crvena.png';
import customPaketMk from '../custom-paket-mk.png';
import customPaketEn from '../custom-paket-en.png';
import './Checkbox.css';
import './Pocetna.css';

// Components
import "react-datepicker/dist/react-datepicker.css";
import {useGlobalSpinnerContext} from "../context/GlobalSpinnerContext";
import {getFamilies, getPublicFamilies} from "../service/FamilyService";
import {
  Accordion,
  AccordionButton,
  Button,
  ButtonGroup,
  Overlay,
  Popover,
  PopoverBody, PopoverHeader,
  ProgressBar
} from "react-bootstrap";
import PublicFamily from "./public/PublicFamily";
import FamilyButtons from "./public/FamilyButtons";
import cover1 from "./do_more_logo.png";
import NewFamilyButtons from "./public/NewFamilyButtons";
import {useMessageSource} from "react-message-source";
import family from "./family.svg";
import {LANGUAGE_EN} from "../service/localization/localization";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import {convertToDenars} from "../service/CurrencyConverter";

const containerWrapper = {
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const PAKET_MK = "https://paket.mk/box2help/";

export default function Pocetna(props) {

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [selected, setSelected] = React.useState(0);
  const [changedOnce, setChangedOnce] = React.useState(false);
  const [selectedVoucher, setSelectedVoucher] = React.useState(500);
  const [selectedFamily, setSelectedFamily] = React.useState(null);
  const [initialFamilies, setInitialFamilies] = React.useState([]);
  const [showPaketHover, setShowPaketHover] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const [paketSodrzinaText, setPaketSodrzinaText] = React.useState('');
  const [customVoucher, setCustomVoucher] = React.useState('');
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext()

  const {getMessage} = useMessageSource('boxtohelp');

  const redirectToPaketMk = () => {
    const familyNumber = initialFamilies.indexOf(selectedFamily) + 1;
    const amount = selectedVoucher == 'custom' ? (props.lang === 'MK' ? customVoucher : convertToDenars(customVoucher)) : selectedVoucher;
    window.location.href = PAKET_MK + selectedFamily.uuid + "/" + familyNumber + "/" + amount;
  }

  const checkDisabledButton = () => {
    const rangeOfCustomVoucher = props.lang === 'MK' ? customVoucher <= 1500 : customVoucher <= 24;
    return selectedVoucher == 'custom' && rangeOfCustomVoucher;
  }

  const changeInput = (value) => {
    console.log(value.target.value)
    setSelectedVoucher(value.target.value);
  }

  const changeCustomVoucher = (e) => {
    const re = /^[1-9]{1}[0-9\b]*$/;

    // if value is not blank, then test the regex

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value <= 1000000) {
      console.log("true")
      setCustomVoucher(e.target.value);
    }
    // setCustomVoucher(Math.trunc(e.target.value));
  }

  const changeSelected = (index) => {
    setSelected(index);
    setSelectedFamily(initialFamilies[index]);
    setChangedOnce(true);
  }

  const paketHover = (e, paketType) => {
    setTarget(e.target);
    setShowPaketHover(true);
    setPaketSodrzinaText(paketType === 1 ? paket1Sodrzina() : paketType === 2 ? paket2Sodrzina() : paket3Sodrzina());
  }

  const paket1Sodrzina = () => (
    <>
    <li>{getMessage('sodrzina.kvasec')}</li>
    <li>{getMessage('sodrzina.brasno')}</li>
    <li>{getMessage('sodrzina.grav')}</li>
    <li>{getMessage('sodrzina.pasteta')} x 2</li>
    <li>{getMessage('sodrzina.maslo')}</li>
    <li>{getMessage('sodrzina.makaroni')}</li>
    <li>{getMessage('sodrzina.nudli')}</li>
    </>
  );

  const paket2Sodrzina = () => (
    <>
      <li>{getMessage('sodrzina.kvasec')}</li>
      <li>{getMessage('sodrzina.brasno')}</li>
      <li>{getMessage('sodrzina.grav')}</li>
      <li>{getMessage('sodrzina.pasteta')} x 2</li>
      <li>{getMessage('sodrzina.maslo')}</li>
      <li>{getMessage('sodrzina.oriz')}</li>
      <li>{getMessage('sodrzina.jajca')}</li>
      <li>{getMessage('sodrzina.tuna')}</li>
      <li>{getMessage('sodrzina.sardina')}</li>
      <li>{getMessage('sodrzina.sapun')}</li>
      <li>{getMessage('sodrzina.pasta')}</li>
      <li>{getMessage('sodrzina.sredstvo')}</li>
    </>
  );

  const paket3Sodrzina = () => (
    <>
      <li>{getMessage('sodrzina.kvasec')}</li>
      <li>{getMessage('sodrzina.brasno')}</li>
      <li>{getMessage('sodrzina.grav')}</li>
      <li>{getMessage('sodrzina.pasteta')} x 4</li>
      <li>{getMessage('sodrzina.maslo')}</li>
      <li>{getMessage('sodrzina.makaroni')}</li>
      <li>{getMessage('sodrzina.oriz')}</li>
      <li>{getMessage('sodrzina.jajca')}</li>
      <li>{getMessage('sodrzina.sol')}</li>
      <li>{getMessage('sodrzina.seker')}</li>
      <li>{getMessage('sodrzina.nudli')} x 5</li>
      <li>{getMessage('sodrzina.grasok')}</li>
      <li>{getMessage('sodrzina.sapun')}</li>
      <li>{getMessage('sodrzina.pasta')}</li>
      <li>{getMessage('sodrzina.sredstvo')}</li>
    </>
  );

  React.useEffect(() => {
    console.log("trying to rerender")
    showSpinner();
    getPublicFamilies()
      .then(response => {
        // console.log(response.data);
        setInitialFamilies(response.data);
        if (response.data.length > 0) {
          setSelectedFamily(response.data[0])
          setSelected(0);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoaded(true);
        hideSpinner();
      });

  }, [isLoaded, showSpinner, hideSpinner])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">

          <div className="domore-project-description-wrapper my-5">
            <h1 className="domore-project-description-title">{ getMessage('title') }</h1>
            <p className="domore-project-description-content mt-5">{ getMessage('description1') }
              <span style={ {color: '#1723CB'} }>{ getMessage('description2') }</span>
              { getMessage('description3') }
              <br/>
              <br/>
              { getMessage('description4') }
              <span style={ {color: '#fc1d37'} }>{ getMessage('description5') }</span>
              { getMessage('description6') }
              <span style={ {color: '#8EB936'} }>{ getMessage('description7') }</span>
              { getMessage('description8') }
              <br/>
              <br/>
              { getMessage('description9') }
            </p>

            <p className="domore-project-description-content-call-to-action">
              { getMessage('description10') }
            </p>
            <p className="domore-project-description-content-call-to-action">
              { getMessage('description11') }
            </p>
            <p className="domore-project-description-content-call-to-action">
              <span style={ {color: '#fc1d37'} }>{ getMessage('description12') }</span>
            </p>
          </div>

          <div className="domore-how-to-wrapper">
            <h2 className="domore-how-to-title">{ getMessage('how.title') }</h2>

            <div className="domore-how-to-steps-wrapper my-4">
              <div className="domore-how-to-step domore-how-to-step1">
                <div>
                  <p className="domore-how-to-step-label">
                    { getMessage('how.step11') }
                  </p>
                  <p className="domore-how-to-step-title">
                    { getMessage('how.step12') }
                  </p>
                </div>
              </div>
              <div className="domore-how-to-step domore-how-to-step2">
                <div>
                  <p className="domore-how-to-step-label">
                    { getMessage('how.step21') }
                  </p>
                  <p className="domore-how-to-step-title">
                    { getMessage('how.step22') }
                  </p>
                </div>
              </div>
              <div className="domore-how-to-step domore-how-to-step3">
                <div>
                  <p className="domore-how-to-step-label">
                    { getMessage('how.step31') }
                  </p>
                  <p className="domore-how-to-step-title">
                    { getMessage('how.step32') }
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-5 domore-families-wrapper">
            <h1 className="domore-families-title">
              { getMessage('semejstva.odberi') }
              <span className="domore-families-total">
                { getMessage('semejstva.vkupno') } <strong>{ initialFamilies.length }</strong>
              </span>
            </h1>

            <div className="my-3">
              <NewFamilyButtons lang={ props.lang } families={ initialFamilies } selected={ selected }
                                changeSelected={ changeSelected }/>
            </div>
          </div>
          {/*{selectedFamily && <div className="container" style={{width: '30%'}}>*/}
          {/*  Донирано {selectedFamily.donated}/{selectedFamily.voucherLimit}*/}
          {/*  <ProgressBar max={selectedFamily.voucherLimit} now={selectedFamily.donated} />*/}
          {/*</div>}*/}

          {/*<div className="mt-3">*/ }
          {/*  <input type="radio" value="500" onClick={changeInput} checked={selectedVoucher == 500}/>*/ }
          {/*  <div className="d-inline">*/ }
          {/*    500*/ }
          {/*    <img src={crna} alt="Box2Help Cover"*/ }
          {/*         style={{width: '10%'}}*/ }
          {/*         className="ml-5 mb-5 mr-5"/>*/ }
          {/*  </div>*/ }
          {/*  <input type="radio" value="1000" checked={selectedVoucher == 1000} onClick={changeInput}/>*/ }
          {/*  <div className="d-inline">*/ }
          {/*    1000*/ }
          {/*    <img src={crvena} alt="Box2Help Cover"*/ }
          {/*         style={{width: '20%'}}*/ }
          {/*         className="ml-5 mb-5 mr-5"/>*/ }
          {/*  </div>*/ }
          {/*  <input type="radio" value="1500" checked={selectedVoucher == 1500} onClick={changeInput}/>*/ }
          {/*  <div className="d-inline">*/ }
          {/*    1500*/ }
          {/*    <img src={plava} alt="Box2Help Cover"*/ }
          {/*         style={{width: '30%'}}*/ }
          {/*         className="ml-5 mb-5 mr-5"/>*/ }
          {/*  </div>*/ }
          {/*</div>*/ }

          <div className={ `domore-packages-wrapper ${ changedOnce ? 'sticky' : '' }` }>
            <h1 className="domore-packages-title">
              { getMessage('paket.odberi') }
            </h1>

            {/*accordion solution*/}

            {/*<Accordion defaultActiveKey="0">*/}
            {/*  <Accordion.Item eventKey="0">*/}
            {/*    <Accordion.Header>Предефинирани</Accordion.Header>*/}
            {/*    <Accordion.Body>*/}
            {/*      <div className="domore-packages-cards-wrapper my-3">*/}
            {/*        <div className={ `domore-package-card ${ selectedVoucher == 500 ? 'selected' : '' }` }*/}
            {/*             onMouseEnter={(event) => paketHover(event, 1)} onMouseLeave={() => setShowPaketHover(false)}>*/}
            {/*          <input type="radio" name="imgbackground" id="img1" className="d-none imgbgchk" value="500"*/}
            {/*                 onChange={ changeInput } checked={ selectedVoucher == 500 }/>*/}
            {/*          <label className="domore-package-card-content" htmlFor="img1">*/}
            {/*            <div className="text-center">*/}
            {/*              <img className="domore-package-card-image" src={ crna }/>*/}
            {/*            </div>*/}
            {/*            <div className="domore-package-amount-wrapper">*/}
            {/*              <div className="domore-package-amount">*/}
            {/*                { getMessage('paket500') }*/}
            {/*              </div>*/}
            {/*              <div className="domore-package-selected-tick"></div>*/}
            {/*            </div>*/}
            {/*          </label>*/}

            {/*        </div>*/}
            {/*        <div className={ `domore-package-card ${ selectedVoucher == 1000 ? 'selected' : '' }` }*/}
            {/*             onMouseEnter={(event) => paketHover(event, 2)} onMouseLeave={() => setShowPaketHover(false)}>*/}
            {/*          <input type="radio" name="imgbackground" id="img2" className="d-none imgbgchk" value="1000"*/}
            {/*                 checked={ selectedVoucher == 1000 } onChange={ changeInput }/>*/}
            {/*          <label className="domore-package-card-content" htmlFor="img2">*/}
            {/*            <div className="text-center">*/}
            {/*              <img className="domore-package-card-image" src={ crvena }/>*/}
            {/*            </div>*/}
            {/*            <div className="domore-package-amount-wrapper">*/}
            {/*              <div className="domore-package-amount">*/}
            {/*                { getMessage('paket1000') }*/}
            {/*              </div>*/}
            {/*              <div className="domore-package-selected-tick"></div>*/}
            {/*            </div>*/}
            {/*          </label>*/}
            {/*        </div>*/}
            {/*        <div className={ `domore-package-card ${ selectedVoucher == 1500 ? 'selected' : '' }` }*/}
            {/*             onMouseEnter={(event) => paketHover(event, 3)} onMouseLeave={() => setShowPaketHover(false)}>*/}
            {/*          <input type="radio" name="imgbackground" id="img3" className="d-none imgbgchk" value="1500"*/}
            {/*                 checked={ selectedVoucher == 1500 } onChange={ changeInput }/>*/}
            {/*          <label className="domore-package-card-content" htmlFor="img3">*/}
            {/*            <div className="text-center">*/}
            {/*              <img className="domore-package-card-image" src={ plava }/>*/}
            {/*            </div>*/}
            {/*            <div className="domore-package-amount-wrapper">*/}
            {/*              <div className="domore-package-amount">*/}
            {/*                { getMessage('paket1500') }*/}
            {/*              </div>*/}
            {/*              <div className="domore-package-selected-tick"></div>*/}
            {/*            </div>*/}
            {/*          </label>*/}
            {/*        </div>*/}
            {/*        /!*custom paket*!/*/}
            {/*        /!*<div className={ `domore-package-card ${ selectedVoucher == 1500 ? 'selected' : '' }` }*!/*/}
            {/*        /!*     onMouseEnter={(event) => paketHover(event, "paket3")} onMouseLeave={() => setShowPaket1(false)}>*!/*/}
            {/*        /!*  <input type="radio" name="imgbackground" id="img3" className="d-none imgbgchk" value="1500"*!/*/}
            {/*        /!*         checked={ selectedVoucher == 1500 } onChange={ changeInput }/>*!/*/}
            {/*        /!*  <label className="domore-package-card-content" htmlFor="img3">*!/*/}
            {/*        /!*    <div className="text-center">*!/*/}
            {/*        /!*      <img className="domore-package-card-image" src={ plava }/>*!/*/}
            {/*        /!*    </div>*!/*/}
            {/*        /!*    <div className="domore-package-amount-wrapper">*!/*/}
            {/*        /!*      <div className="domore-package-amount">*!/*/}
            {/*        /!*        <input type='text' style={{ width: '100px', height: '40px'}}/>mkd*!/*/}
            {/*        /!*      </div>*!/*/}
            {/*        /!*      <div className="domore-package-selected-tick"></div>*!/*/}
            {/*        /!*    </div>*!/*/}
            {/*        /!*  </label>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*      </div>*/}
            {/*      <div className="my-3 pb-3 text-center">*/}
            {/*        <button*/}
            {/*          style={ {*/}
            {/*            border: 'solid 1px #1723CB',*/}
            {/*            borderColor: '#1723CB',*/}
            {/*            color: '#fff',*/}
            {/*            backgroundColor: '#1723CB'*/}
            {/*          } }*/}
            {/*          className="btn btn-lg" onClick={ redirectToPaketMk }>*/}
            {/*          <b>{ getMessage('doniraj.button') }</b>*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*    </Accordion.Body>*/}
            {/*  </Accordion.Item>*/}
            {/*  <Accordion.Item eventKey="1">*/}
            {/*    <Accordion.Header>Прилагоден<div className="domore-package-selected-tick"></div></Accordion.Header>*/}
            {/*    <Accordion.Body>*/}
            {/*     <div className="text-center mt-5">*/}
            {/*       /!*<label htmlFor="customInput">Внесете сума на пакет</label><br/>*!/*/}
            {/*       /!*<input type="number" className="domore-custom-amount" id="customInput"/>*!/*/}
            {/*       <div className="input-group justify-content-center">*/}
            {/*         <input type="text" className="" aria-label="Amount" value={customVoucher}*/}
            {/*                onChange={changeCustomVoucher}/>*/}
            {/*           <div className="input-group-append">*/}
            {/*             <span className="input-group-text">мкд</span>*/}
            {/*           </div>*/}
            {/*       </div>*/}
            {/*     </div>*/}
            {/*      <div className="my-3 pb-3 text-center">*/}
            {/*        <button*/}
            {/*          style={ {*/}
            {/*            border: 'solid 1px #1723CB',*/}
            {/*            borderColor: '#1723CB',*/}
            {/*            color: '#fff',*/}
            {/*            backgroundColor: '#1723CB'*/}
            {/*          } }*/}
            {/*          disabled={customVoucher == ''}*/}
            {/*          className="btn btn-lg" onClick={ redirectToPaketMkCustomVoucher }>*/}
            {/*          <b>{ getMessage('doniraj.button') }</b>*/}
            {/*        </button>*/}
            {/*      </div>*/}
            {/*    </Accordion.Body>*/}
            {/*  </Accordion.Item>*/}
            {/*</Accordion>*/}


                  <Overlay show={showPaketHover} target={target} placement="top">
                    <Popover>
                      <PopoverHeader>
                        {getMessage('sodrzina.title')}
                      </PopoverHeader>
                      <PopoverBody>
                        {paketSodrzinaText}
                      </PopoverBody>
                    </Popover>
                  </Overlay>

            <div className="domore-packages-cards-wrapper my-3">
              <div className={ `domore-package-card ${ selectedVoucher == 500 ? 'selected' : '' }` }
                   onMouseEnter={(event) => paketHover(event, 1)} onMouseLeave={() => setShowPaketHover(false)}>
                <input type="radio" name="imgbackground" id="img1" className="d-none imgbgchk" value="500"
                       onChange={ changeInput } checked={ selectedVoucher == 500 }/>
                <label className="domore-package-card-content" htmlFor="img1">
                  <div className="text-center">
                    <img className="domore-package-card-image" src={ crna }/>
                  </div>
                  <div className="domore-package-amount-wrapper">
                    <div className="domore-package-amount">
                      { getMessage('paket500') }
                    </div>
                    <div className="domore-package-selected-tick"></div>
                  </div>
                </label>

              </div>
              <div className={ `domore-package-card ${ selectedVoucher == 1000 ? 'selected' : '' }` }
                   onMouseEnter={(event) => paketHover(event, 2)} onMouseLeave={() => setShowPaketHover(false)}>
                <input type="radio" name="imgbackground" id="img2" className="d-none imgbgchk" value="1000"
                       checked={ selectedVoucher == 1000 } onChange={ changeInput }/>
                <label className="domore-package-card-content" htmlFor="img2">
                  <div className="text-center">
                    <img className="domore-package-card-image" src={ crvena }/>
                  </div>
                  <div className="domore-package-amount-wrapper">
                    <div className="domore-package-amount">
                      { getMessage('paket1000') }
                    </div>
                    <div className="domore-package-selected-tick"></div>
                  </div>
                </label>
              </div>
              <div className={ `domore-package-card ${ selectedVoucher == 1500 ? 'selected' : '' }` }
                   onMouseEnter={(event) => paketHover(event, 3)} onMouseLeave={() => setShowPaketHover(false)}>
                <input type="radio" name="imgbackground" id="img3" className="d-none imgbgchk" value="1500"
                       checked={ selectedVoucher == 1500 } onChange={ changeInput }/>
                <label className="domore-package-card-content" htmlFor="img3">
                  <div className="text-center">
                    <img className="domore-package-card-image" src={ plava }/>
                  </div>
                  <div className="domore-package-amount-wrapper">
                    <div className="domore-package-amount">
                      { getMessage('paket1500') }
                    </div>
                    <div className="domore-package-selected-tick"></div>
                  </div>
                </label>
              </div>
              {/*custom paket*/}
              <div className={ `domore-package-card ${ selectedVoucher == 'custom' ? 'selected' : '' }` }>
                <input type="radio" name="imgbackground" id="img4" className="d-none imgbgchk" value="custom"
                       checked={ selectedVoucher == 'custom' } onChange={ changeInput }/>
                <label className="domore-package-card-content" htmlFor="img4">
                  <div className="text-center">
                    <img className="domore-package-card-image" src={ props.lang === 'EN' ? customPaketEn : customPaketMk }/>
                    {/*<div className="domore-custom-div"><b>ДОНИРАЈ ПОГОЛЕМА СУМА НА ПАКЕТ:</b></div>*/}
                  </div>
                  <div className="domore-package-amount-wrapper">
                    <div className="domore-package-amount">
                      <input type='text' style={{ width: '160px', height: '40px', border: '1px solid #1723CB', borderRadius: '5px'}} value={customVoucher} onChange={changeCustomVoucher}/>{getMessage('semejstvo.currency')}
                    </div>
                    <div className="domore-package-selected-tick"></div>
                  </div>
                </label>
              </div>
            </div>

            <div className="my-3 pb-3 text-center">
              <button
                disabled={checkDisabledButton()}
                style={ {
                  border: 'solid 1px #1723CB',
                  borderColor: '#1723CB',
                  color: '#fff',
                  backgroundColor: '#1723CB'
                } }
                className="btn btn-lg" onClick={ redirectToPaketMk }>
                <b>{ getMessage('doniraj.button') }</b>
              </button>
            </div>
          </div>


          {/*<br/>*/ }
          {/*<img src={cover} alt="Box2Help Cover"/>*/ }
          {/*<br/>*/ }
          {/*<br/>*/ }
          {/*<p>Бидејќи сакаме да овозможиме донаторско искуство за секој, овозможуваме ваучери од 500, <span*/ }
          {/*  style={{color: '#fc1d37'}}>1000</span> и <span style={{color: '#1723CB'}}>1500</span> денари.*/ }
          {/*  Поради различниот состав и потреба на засегнатите семејства, пакетите можат да бидат различни со различни*/ }
          {/*  намирници. Нашите предлог пакети за секоја од сумите се следниве:</p>*/ }
          {/*<div>*/ }
          {/*  <img src={cover1} alt="Box2Help Cover"*/ }
          {/*       style={{width: '200px', height: '150px', border: 'solid 1px #fc1d37', borderColor: '#fc1d37'}}*/ }
          {/*       className="m-5"/>*/ }
          {/*  <img src={cover1} alt="Box2Help Cover"*/ }
          {/*       style={{width: '200px', height: '150px', border: 'solid 1px #fc1d37', borderColor: '#fc1d37'}}*/ }
          {/*       className="m-5"/>*/ }
          {/*  <img src={cover1} alt="Box2Help Cover"*/ }
          {/*       style={{width: '200px', height: '150px', border: 'solid 1px #fc1d37', borderColor: '#fc1d37'}}*/ }
          {/*       className="m-5"/>*/ }
          {/*</div>*/ }
        </div>
      </div>
    </div>
  );
}
