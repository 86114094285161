import * as React from 'react';

// Components
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

// Services
import {login} from "../../service/AuthService";
import isEmail from "validator/es/lib/isEmail";


export default function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [checkBtn, setCheckBtn] = React.useState(null);
  const [form, setForm] = React.useState(null);

  const validEmail = value => {
    if(!isEmail(value)) {
      return (
        <div className="alert alert-danger mt-1" role="alert">
          This is not a valid email.
        </div>
      );
    }
  }

  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger mt-1" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const pushPath = () => {
    return props.location.state ? props.location.state.from.pathname : "/families";
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.validateAll();

    if (checkBtn.context._errors.length === 0) {
      login(email, password).then(
        () => {
          props.history.push(pushPath());
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="container">
      <div className="col-lg-12">
        <div className="card card-container m-5 p-5">
          <Form
            onSubmit={handleLogin}
            ref={c => {
              setForm(c);
            }}
          >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={onChangeEmail}
                validations={[required, validEmail]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"/>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                setCheckBtn(c);
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
}
