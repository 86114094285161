import * as React from 'react';
import moment from "moment";

// Components
import * as Icon from "react-bootstrap-icons";
import FamilyRow from "./FamilyRow";

// Services
import {getFamilies} from "../../service/FamilyService";
import {useGlobalSpinnerContext} from "../../context/GlobalSpinnerContext";
import {Link} from "react-router-dom";

export default function Families() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [initialFamilies, setInitialFamilies] = React.useState([]);
  const [filteredAppointments, setFilteredAppointments] = React.useState([]);
  const [selected, setSelected] = React.useState("all");
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext()

  React.useEffect(() => {
    if (!isLoaded) {
      showSpinner();
      getFamilies()
        .then(response => {
          console.log(response.data);
          setInitialFamilies(response.data);
          setFilteredAppointments(response.data);
          setIsLoaded(true);
          hideSpinner();
        })
        .catch(() => {
          setIsLoaded(true);
          hideSpinner();
        });
    }
  }, [isLoaded, showSpinner, hideSpinner])

  React.useEffect(() => {
    const today = moment();
    console.log(today);
    const tomorrow = moment().add(1, 'day');
    console.log(tomorrow);
    if (selected === "all") {
      setFilteredAppointments(initialFamilies);
    }
    if (selected === "today") {
      setFilteredAppointments(initialFamilies.filter(appointment => today.isSame(appointment.startTime, "days")));
    }
    if (selected === "tomorrow") {
      setFilteredAppointments(initialFamilies.filter(appointment => tomorrow.isSame(appointment.startTime, "days")))
    }
  }, [selected, initialFamilies])

  const styleAllAppointments = () => selected === "all" ? "btn btn-info m-1" : "btn btn-outline-info m-1";
  const styleTodayAppointments = () => selected === "today" ? "btn btn-info m-1" : "btn btn-outline-info m-1";
  const styleTomorrowAppointments = () => selected === "tomorrow" ? "btn btn-info m-1" : "btn btn-outline-info m-1";

  return (
    <div className="m-5">
      <div className="mb-5">
        <h2>Семејства</h2>
        {/*<div className="text-center">*/}
        {/*  <button className={styleAllAppointments()} onClick={() => setSelected("all")}>All</button>*/}
        {/*  <button className={styleTodayAppointments()} onClick={() => setSelected("today")}>Today</button>*/}
        {/*  <button className={styleTomorrowAppointments()} onClick={() => setSelected("tomorrow")}>Tomorrow</button>*/}
        {/*</div>*/}
      </div>
      <table className="table table-striped">
        <thead>
        <tr>
          <th scope="col">Презиме</th>
          {/*{(isDoctorRole() || isAdminRole()) && <th scope="col">Patient</th>}*/}
          {/*{(!isDoctorRole() || isAdminRole()) && <th scope="col">Doctor</th>}*/}
          <th scope="col">Име</th>
          <th scope="col">Телефон</th>
          <th scope="col">Адреса</th>
          <th scope="col">Акции</th>
        </tr>
        </thead>
        <tbody>
        {initialFamilies.map(family =>
          <FamilyRow key={family.id} family={family} setIsLoaded={setIsLoaded} />)}
        </tbody>
      </table>
      <Link to="/new/family" className="btn btn-info">Додај семејство</Link>
    </div>

  )
}
