import cover_mk from './domore-cover.png';
// TODO: Add COVER image in EN and change this path
import cover_en from './domore-cover.png';
import ljubeznost from './ljubeznost-logo-100-sb.png';
import paketmk from './paketmk-logo.png';
import dobitnik from './b2help-dobitnik.jpg';
import crvena from './[RGB] BOX2HELP-crvena.png';
import family from './family.svg';
import iconChevronRight from './icon-chevron-right.svg';
import {Link} from "react-router-dom";
import * as React from "react";
import {getDonations} from "../service/FamilyService";
import {useGlobalSpinnerContext} from "../context/GlobalSpinnerContext";
import {useMessageSource} from "react-message-source";
import {Carousel} from 'react-bootstrap';
import './ZaNas.css';
import {convertToEuros} from "../service/CurrencyConverter";

export default function ZaNas(props) {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [vouchers, setVouchers] = React.useState(0);
  const [money, setMoney] = React.useState(0);
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext();

  const {getMessage} = useMessageSource('zanas');

  const currencyFormatter = (amount) => {
    return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  React.useEffect(() => {
    if (!isLoaded) {
      showSpinner();
      getDonations()
        .then(response => {
          setVouchers(response.data.vouchers);
          setMoney(response.data.money);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoaded(true);
          hideSpinner();
        });
    }
  }, [isLoaded, showSpinner, hideSpinner])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">

          <div className="domore-box-card-wrapper my-5">
            <div className="domore-box-card call-to-action">
              <div className="text-center">
                <img className="domore-box-card-image-small" src={ crvena }/>
              </div>
              <p className="domore-box-card-description">
                { getMessage('boxtohelp.button.description1') }
                <br/>
                { getMessage('boxtohelp.button.description2') }
              </p>
              <Link to="/doniraj" className="btn domore-box-card-action-button">
                { getMessage('boxtohelp.button.text') }
              </Link>
            </div>

            <div className="domore-box-card stats">
              <p className="domore-box-card-title">{ getMessage('akcii.title') }</p>
              <p className="domore-box-card-num-donations">{ vouchers }+</p>
              <p className="domore-box-card-description">{ getMessage('akcii.description') }:</p>
              <p className="domore-box-card-donated-amount">
                <span>{ props.lang === 'MK' ? currencyFormatter(money) : currencyFormatter(convertToEuros(money)) }</span>
                <span className="domore-box-card-donated-amount-currency">
              { getMessage('akcii.currency') }
            </span>
              </p>
            </div>

            <div className="domore-box-card call-to-action">
              <div className="text-center">
                <img className="domore-box-card-image-small" src={ family }/>
              </div>
              <p className="domore-box-card-description">
                { getMessage('prijavi.button.description1') }
                <br/>
                { getMessage('prijavi.button.description2') }
              </p>
              <a className="btn domore-box-card-action-button"
                 href="mailto:mkdomore@gmail.com?subject=Report a socially threatened family"
                 target="_blank">
                { getMessage('prijavi.button.text') }
              </a>
            </div>
          </div>

          <div className="my-4">
            <Link to="/doniraj">
              <img src={ props.lang === 'MK' ? cover_mk : cover_en } alt="Box2Help Cover" className="w-100"/>
            </Link>
          </div>

          <br/>

          <div className="domore-become-wrapper my-4">
            {/*TODO: add correct link href to "become partner" CTA page*/ }
            <Link className="domore-become-call-to-action-wrapper" to="#" onClick={(e) => {
              window.location.href = "mailto:mkdomore@gmail.com";
              e.preventDefault();
            }}>
              <div className="domore-become-call-to-action">
                <div className="domore-become-call-to-action-content-wrapper">
                  <p className="domore-become-call-to-action-content-title-small">
                    { getMessage('staninapravipoveke.title') }
                  </p>
                  <h3 className="domore-become-call-to-action-content-title">
                    { getMessage('staninapravipoveke.partner.title') }
                  </h3>
                  {/*TODO: Add the description in the messages files*/}
                  <p className="domore-become-call-to-action-content-description">
                    { getMessage('staninapravipoveke.partner.description') }
                  </p>
                </div>
                <div className="domore-become-call-to-action-link-wrapper">
                  <img src={ iconChevronRight }/>
                </div>
              </div>
            </Link>
          </div>

          <br/>

          <div className="row my-4 domore-partners-awards-wrapper">
            <div className="col-12 col-lg-6 d-inline-flex flex-column align-items-center">
              <h2 className="text-center" style={ {color: '#fc1d37'} }><b>{ getMessage('partneri.title') }</b></h2>
              <Carousel className="domore-partners-carousel"
                        controls={ false } indicators={ true } variant="dark" interval="3000">
                <Carousel.Item className="h-100">
                  <a href="https://paket.mk/" target="_blank" className="domore-partners-carousel-image-link">
                    <img src={ paketmk } alt="Box2Help Cover" className="domore-partners-carousel-image"/>
                  </a>
                </Carousel.Item>
                <Carousel.Item className="h-100">
                  <a href="https://www.ljubeznost.mk/" target="_blank" className="domore-partners-carousel-image-link">
                    <img src={ ljubeznost } alt="Box2Help Cover" className="domore-partners-carousel-image"/>
                  </a>
                </Carousel.Item>
              </Carousel>
            </div>

            <div className="col-12 col-lg-6 d-inline-flex flex-column align-items-center">
              <h2 className="text-center" style={ {color: '#fc1d37'} }><b>{ getMessage('nagradi.title') }</b></h2>
              <br/>
              <h4 className="text-center mb-3"
                  style={ {fontWeight: '400', fontSize: '24px', lineHeight: '28px'} }>
                { getMessage('nagradi.description1') }
                <span style={ {color: '#fc1d37'} }>&nbsp;{ getMessage('nagradi.description2') }&nbsp;</span>
                { getMessage('nagradi.description3') }
              </h4>
              <div className="w-50"><img src={ dobitnik } className="img-fluid"/></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
