import React from 'react';
import {Redirect, Route} from 'react-router-dom';

// Services
import {getCurrentUser} from "../../service/AuthService";

export default function PrivateRoute({ component: Component, ...rest }) {

  return(
    <Route {...rest} render={props => getCurrentUser() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
    )}
    />
  );
}
