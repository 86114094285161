import React, { useState } from 'react';
import {Form, Button, Toast} from 'react-bootstrap';
import {addNewFamily, patchFamily} from "../../service/FamilyService";
import {useGlobalSpinnerContext} from "../../context/GlobalSpinnerContext";
import {useHistory} from "react-router-dom";

const EditFamily = (props) => {
  const [family, setFamily] = useState({
    firstName: props.family ? props.family.firstName : '',
    lastName: props.family ? props.family.lastName : '',
    phone: props.family ? props.family.phone : '',
    address: props.family ? props.family.address : '',
    description: props.family ? props.family.description : '',
    descriptionEn: props.family ? props.family.descriptionEn : '',
    voucherLimit: props.family ? props.family.voucherLimit : ''
  });

  const history = useHistory();
  const [, showSpinner, hideSpinner] = useGlobalSpinnerContext()

  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(false);
  const { firstName, lastName, phone, address, description, descriptionEn, voucherLimit } = family;

  const editFamily = (family) => {
    showSpinner();
    patchFamily(props.family.id, family)
      .then(response => {
        console.log(response);
        props.setShow(false);
        history.push("/families");
      })
      .catch(error => {
        console.log(error);
        setError(true);
      })
      .finally(() => hideSpinner());
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [firstName, lastName, phone, address, description, descriptionEn, voucherLimit];
    let errorMsg = '';

    const allFieldsFilled = values.every((field) => {
      const value = `${field}`.trim();
      return value !== '' && value !== '0';
    });

    if (allFieldsFilled) {
      const family = {
        firstName,
        lastName,
        phone,
        address,
        description,
        descriptionEn,
        voucherLimit
      };
      editFamily(family);
    } else {
      errorMsg = 'Внеси ги сите полиња.';
    }
    setErrorMsg(errorMsg);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'phone':
        if (value === '' || value.match(/^\d{1,}$/)) {
          setFamily((prevState) => ({
            ...prevState,
            [name]: value
          }));
        }
        break;
      case 'voucherLimit':
        if (value === '' || value.match(/^\d{1,}$/)) {
          setFamily((prevState) => ({
            ...prevState,
            [name]: value
          }));
        }
        break;
      default:
        setFamily((prevState) => ({
          ...prevState,
          [name]: value
        }));
    }
  };

  return (
    <div className="m-5 main-form">
      {errorMsg && <p className="alert-danger">{errorMsg}</p>}
      <Form onSubmit={handleOnSubmit}>
        <Form.Group controlId="firstName">
          <Form.Label>Име</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="firstName"
            value={firstName}
            placeholder="Внеси име"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Презиме</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="lastName"
            value={lastName}
            placeholder="Внеси презиме"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label>Телефон</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="phone"
            value={phone}
            placeholder="Внеси телефон"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>Адреса</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="address"
            value={address}
            placeholder="Внеси адреса"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Опис</Form.Label>
          <Form.Control
            className="input-control"
            as="textarea"
            name="description"
            value={description}
            placeholder="Внеси опис"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="descriptionEn">
          <Form.Label>Англиски Опис</Form.Label>
          <Form.Control
            className="input-control"
            as="textarea"
            name="descriptionEn"
            value={descriptionEn}
            placeholder="Внеси англиски опис"
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group controlId="voucherLimit">
          <Form.Label>Ваучер Лимит</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="voucherLimit"
            value={voucherLimit}
            placeholder="Enter voucher limit"
            onChange={handleInputChange}
          />
        </Form.Group>


        {error && <div className="mt-2 mb-2 p-3 alert-danger">Настана грешка при едитирање</div>}
        <Button variant="primary" type="submit" className="mt-3 submit-btn btn-info">
          Едитирај
        </Button>
      </Form>
    </div>
  );
};

export default EditFamily;
