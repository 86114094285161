import {Button, ButtonGroup, Carousel, ProgressBar} from "react-bootstrap";
import * as React from "react";
import "./NewFamilyButtons.css";
import {useMessageSource} from "react-message-source";
import {convertToEuros} from "../../service/CurrencyConverter";

export default function NewFamilyButtons(props) {

  const {getMessage} = useMessageSource('boxtohelp');

  const selectFamily = (family, familyIndex) => {
    // No need to try and select the same item again, since it's still selected
    if (familyIndex === props.selected) return;
    props.changeSelected(familyIndex);
  }

  // TODO: Uncomment this if you want the carousel solution to work
  // We currently split all the families into multiple chunks consisted of 6 families per chunk
  // This method that returns the index of the item if the array had not been split into chunks
  // EG: if item was in second chunk, on index 2:
  // that means its 6(first chunk size) + 2(indexInChunk) = index #8 in the total array of families

  // const carouselChunkSize = 6;
  // const calculateItemIndex = (chunkNumber, indexInCurrentChunk) => {
  //   return chunkNumber * carouselChunkSize + indexInCurrentChunk;
  // }
  //
  // const familiesChunks = [];
  // if (props.families) {
  //   for (let i = 0; i < props.families.length; i += carouselChunkSize) {
  //     const chunk = props.families.slice(i, i + carouselChunkSize);
  //     familiesChunks.push(chunk);
  //   }
  // }

  return (
    // Split families into batches of 6families per batch so that we can show them in one CarouselItem

    // TODO: uncomment this if you want the Solution with a carousel
    // <Carousel className="domore-families-carousel"
    //           controls={ true } indicators={ true } nextLabel={ null } prevLabel={ null } interval={ null }
    //           pause={ false } variant="light">
    //   {
    //     familiesChunks.map((families, chunkIndex) => {
    //       return (<Carousel.Item key={ `chunk-${ chunkIndex + 1 }` } className="h-100">
    //         <div className="domore-families-carousel-group">
    //           {
    //             families.map((family, indexInsideChunk) => {
    //               const familyIndex = calculateItemIndex(chunkIndex, indexInsideChunk);
    //               return <button key={ `family-${ indexInsideChunk + 1 }` }
    //                              className={ `btn domore-family-item ${ familyIndex === props.selected ? 'selected' : '' }` }
    //                              onClick={ () => selectFamily(family, familyIndex) }>
    //                 <h5 className="domore-family-item-title">Семејство { familyIndex + 1 }</h5>
    //                 { props.lang === 'MK' ? family.description : family.descriptionEn }
    //               </button>
    //
    //             })
    //           }
    //         </div>
    //       </Carousel.Item>)
    //     })
    //   }
    // </Carousel>
    //
    <div className="domore-families-carousel-group">
    {
      props.families.map((family, familyIndex) => {
        return <button key={ `family-${ familyIndex }` } disabled={family.donated >= family.voucherLimit}
                       className={ `btn domore-family-item ${ familyIndex === props.selected ? 'selected' : '' }` }
                       onClick={ () => selectFamily(family, familyIndex) }>
          <h5 className="domore-family-item-title">{getMessage('semejstvo.title')} { familyIndex + 1 }</h5>
          { props.lang === 'MK' ? family.description : family.descriptionEn }
          <br/>
          <br/>
          <br/>
          <p style={{ position: 'absolute', bottom: '0', marginTop: '5px'}}>
            <b>{getMessage('semejstvo.donated')} {props.lang === 'MK' ? family.donated : convertToEuros(family.donated)}/{props.lang === 'MK' ? family.voucherLimit : convertToEuros(family.voucherLimit)}{getMessage('semejstvo.currency')}</b>
            <ProgressBar max={family.voucherLimit} now={family.donated} />
          </p>
        </button>

      })
    }
    </div>
  )
}
