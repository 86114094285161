import axios from '../custom-axios/axios'
import authHeader from "./auth-header";

export function getFamilies() {
  return axios.get("/api/families/", { headers: authHeader() }).then(data => data);
}

export function addNewFamily(family) {
  return axios.post("/api/families/", family, { headers: authHeader()}).then(data => data);
}

export function patchFamily(id, family) {
  return axios.patch("/api/families/" + id, family, { headers: authHeader()}).then(data => data);
}

export function getFamily(id) {
  return axios.get("/api/families/" + id, { headers: authHeader() }).then(data => data);
}

export function getPublicFamilies() {
  return axios.get("/public/families").then(data => data);
}

export function getDonations() {
  return axios.get("/public/donations").then(data => data);
}

export function getDonors() {
  return axios.get("/public/donors").then(data => data);
}
